import WhiteCardContainer from '../../../../../components/containers/forms/WhiteCardContainer';
import FormFieldsContainer from '../../../../../components/containers/forms/FormFieldsContainer';
import ProfileFormHeader from '../../ProfileFormHeader/ProfileFormHeader';
import FormInputField from '../../../../../components/formElements/FormInputField';
import HalfRow from '../../../../../components/formElements/HalfRow';
import CustomRedAlert from '../../../../../components/alerts/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import useDoctorServices from './hooks/useDoctorServices';

export default function DoctorServices(props) {
    const { services, queryAllDoctorServices, selectedUser } = props;

    console.log(services, 'services');
    const {
        saveServices,
        isEditing,
        showErrorModal,
        errorBody,
        closeErrorMessage,
        showSuccessModal,
        successBody,
        onCloseSuccessMessage,
        inputValue,
        setInputValue,
        deleteService,
        modalTitle,
    } = useDoctorServices(queryAllDoctorServices, selectedUser);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            saveServices({ name: inputValue, idDoctorUser: selectedUser.id });
        }
    };

    return (
        <>
            <form>
                <WhiteCardContainer>
                    <FormFieldsContainer>
                        <ProfileFormHeader
                            title={'Servicios ofrecidos'}
                            initialText={
                                'Por favor, ingrese los servicios que ofrece. Escriba el servicio en el campo de entrada y presione Enter o haga clic en "Agregar Servicio" para añadirlo a la lista.'
                            }
                        />
                        <div className="grid grid-cols-6 gap-6">
                            <HalfRow>
                                <FormInputField
                                    identifier={'service'}
                                    label={'Servicio'}
                                    type={'text'}
                                    placeholder={'Ingrese el nombre del servicio'}
                                    helperText={'Requerido'}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                    value={inputValue}
                                />
                            </HalfRow>
                        </div>
                    </FormFieldsContainer>
                </WhiteCardContainer>
                <div className="flex flex-wrap pt-10 pl-12">
                    {
                        services.map((service, index) => (
                            <div class="py-2 px-8 !w-auto  flex justify-between  items-center gap-2 shadow-md no-underline rounded-full light-bg-blue text-gray font-sans font-semibold text-sm border-blue btn-primary hover:text-gray hover:bg-blue-light focus:outline-none active:shadow-none mr-2 mb-5">
                                {service.name}
                            <span onClick={() => deleteService(service.id)} className="cursor-pointer pl-3 text-xl text-gray-500 font-bold">x</span>
                            </div>
                        ))
                    }
                
                </div>
                {showErrorModal && (
                    <CustomRedAlert
                        open={true}
                        title={'Ocurrió un error.'}
                        bodyText={errorBody}
                        onClose={closeErrorMessage}
                    />
                )}

                {showSuccessModal && (
                    <CustomSuccessModal
                        title={modalTitle}
                        bodyText={successBody}
                        buttonText={'Aceptar'}
                        buttonAction={onCloseSuccessMessage}
                        open={showSuccessModal}
                        onClose={onCloseSuccessMessage}
                    />
                )}
            </form>
        </>
    );
}
