import { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import FormButton from '../../components/formElements/FormButton';
import { FormFieldsContainer, WhiteCardContainer } from '../../components';
import Loader from '../../components/Loader/Loader';
import { ProfileFormHeader } from '../Users/components/ProfileFormHeader';
import DeleteConfirmationModal from '../../components/alerts/deleteConfirmationModal/DeleteConfirmationModal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { plan } from '../../services/Plans';
import { planCharacteristic } from '../../services/PlanCharacteristic';

const Plans = () => {
    const [loading, setLoading] = useState(true);
    // const [openDeleteModal, setOpenDeleteModal] = useState(null);
    const [deletePlanId, setDeletePlanId] = useState(null);
    const [deleteCharacteristicId, setDeleteCharacteristicId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [blog, setBlog] = useState({
        title: '',
        price: '',
        annualPrice: '',
        description: '',
        enabledCharacteristics: []
    });
    const [plansId, setPlansId] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [plans, setPlans] = useState([]);
    const [characteristics, setCharacteristics] = useState([]);
    const [currentCharacteristic, setCurrentCharacteristic] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [isCharacteristicModalOpen, setIsCharacteristicModalOpen] = useState(false);
    const [selectedCharacteristics, setSelectedCharacteristics] = useState([]);
    const validateForm = () => {
        let errors = {};
        if (!blog.title || blog.title.length < 3) errors.title = 'El nombre debe tener al menos 3 caracteres';
        if (!blog.price || isNaN(blog.price) || blog.price <= 0) errors.price = 'El precio debe ser un número positivo';
        if (!blog.annualPrice || isNaN(blog.annualPrice) || blog.annualPrice <= 0)
            errors.annualPrice = 'El precio anual debe ser un número positivo';
        if (!blog.description || blog.description.trim() === '') errors.description = 'La descripción es obligatoria';
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    useEffect(() => {
        fetchPlans();
        fetchCharacteristics();
    }, []);

    const fetchPlans = async () => {
        try {
            const response = await plan.get();
            setPlans(response.data);
            setLoading(false);
        } catch (err) {
            setError('Error fetching plans');
            setLoading(false);
        }
    };

    const fetchCharacteristics = async () => {
        try {
            const response = await planCharacteristic.get();
            setCharacteristics(response.data);
        } catch (err) {
            setError('Error fetching characteristics');
        }
    };

    const handleEditClick = (plan) => {
        const formattedDescription = plan.description.replace(/<\/p><p>/g, "<br>");
        setEditMode(true);
        setBlog({
            title: plan.name,
            price: Number(plan.priceIva),
            annualPrice: Number(plan.priceAnnual),
            description: formattedDescription
        });
        setPlansId(plan.id);
        const enabledCharacteristics = plan.enabledCharacteristics ? JSON.parse(plan.enabledCharacteristics) : [];
        setSelectedCharacteristics(enabledCharacteristics);
        openModal();
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false);
        setError('');
        setFormErrors({});
        setSelectedCharacteristics([])
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const body = {
                name: blog.title,
                priceAnnual: Number(blog.annualPrice),
                priceIva: Number(blog.price),
                description: blog.description,
                enabledCharacteristics: JSON.stringify(selectedCharacteristics)
            };

            if (editMode) {
                await plan.edit({ id: plansId, ...body });
                setPlans(plans.map((plan) => (plan.id === plansId ? { ...plan, ...body } : plan)));
            } else {
                const response = await plan.add(body);
                const newPlan = response.data;
                setPlans([...plans, newPlan]);
            }
            setBlog({ title: '', price: '', annualPrice: '', description: '' });
            setSelectedCharacteristics([]);
            setFormErrors({});
            closeModal();
        } catch (err) {
            setError(err.response.data.message);
        }
    };

    const handleDelete = async () => {
        try {
            if (deletePlanId) { 
                await plan.delete(deletePlanId);
                setPlans(plans.filter((plan) => plan.id !== deletePlanId));
                setDeletePlanId(null);
            } else if (deleteCharacteristicId) {
                await planCharacteristic.delete(deleteCharacteristicId);
                setCharacteristics(characteristics.filter((characteristic) => characteristic.id !== deleteCharacteristicId));
                setDeleteCharacteristicId(null);
            }
        } catch (err) {
            setError('Error deleting '+ (deletePlanId ? 'plan' : 'characteristic'));
        }
    };

    const openCharacteristicModal = () => setIsCharacteristicModalOpen(true);
    const closeCharacteristicModal = () => {
        setIsCharacteristicModalOpen(false);
        setCurrentCharacteristic('');
        setEditIndex(null);
    };

    const handleAddOrUpdateCharacteristic = async () => {
        try {
            if (editIndex !== null) {
             
                const updatedCharacteristics = [...characteristics];
                updatedCharacteristics[editIndex].name = currentCharacteristic;
                await planCharacteristic.edit({ id: characteristics[editIndex].id, name: currentCharacteristic });

                setCharacteristics([...updatedCharacteristics]);
            } else {
                const response = await planCharacteristic.add({ name: currentCharacteristic });
                setCharacteristics([...characteristics, response.data]);
            }
            closeCharacteristicModal();
        } catch (err) {
            setError('Error saving characteristic');
        }
    };

    const handleEditCharacteristic = (index) => {
        setCurrentCharacteristic(characteristics[index].name);
        setEditIndex(index);
        openCharacteristicModal();
    };

    const handleDeleteCharacteristic = async (index) => {
        try {
            await planCharacteristic.delete(characteristics[index].id);
            setCharacteristics(characteristics.filter((_, i) => i !== index));
        } catch (err) {
            setError('Error deleting characteristic');
        }
    };

    if (loading) {
        return <Loader />;
    }
    const handleCharacteristicChange = (charId) => {
        setSelectedCharacteristics((prev) =>
            prev.includes(charId) ? prev.filter((id) => id !== charId) : [...prev, charId]
        );
    };

    const onModalClose = () => {
        setDeletePlanId(null);
        setDeleteCharacteristicId(null);
    }

    return (
        <>
            <WhiteCardContainer>
                <FormFieldsContainer>
                    <ProfileFormHeader title="Planes" />
                </FormFieldsContainer>
                <div className="px-6 mb-3">
                    <button
                        onClick={() => {
                            setEditMode(false);
                            openModal();
                            setBlog({});
                        }}
                        className="bg-dl-primary-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-500 disabled:bg-dl-primary-500"
                    >
                        Agregar Nuevo Plan
                    </button>
                </div>

                <div className="px-4 sm:px-6 lg:px-8 mb-10">
                    <div className="flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Nombre
                                                </th>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Descripción
                                                </th>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Acciones
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {plans.map((plan) => (
                                                <tr key={plan.id}>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {plan.name}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div dangerouslySetInnerHTML={{ __html: plan.description }} />
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="flex gap-2">
                                                            <button
                                                                onClick={() => handleEditClick(plan)}
                                                                className="text-dl-primary-900 hover:text-dl-accent cursor-pointer"
                                                            >
                                                                Editar
                                                            </button>
                                                            <button
                                                                onClick={() => setDeletePlanId(plan.id)}
                                                                className="text-dl-primary-900 hover:text-dl-accent cursor-pointer"
                                                            >
                                                                Borrar
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {(deletePlanId || deleteCharacteristicId) && (
                    <DeleteConfirmationModal
                        open={Boolean(deletePlanId || deleteCharacteristicId)}
                        onClose={() => onModalClose()}
                        onCancel={() => onModalClose()}
                        onConfirm={() => handleDelete()}
                        title={deletePlanId ? '¿Quieres eliminar el plan?': '¿Quieres eliminar la característica?'}
                        bodyText={'Esta acción es permanente.'}
                    />
                )}

                <Transition appear show={isModalOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>
                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            {editMode ? 'Editar Plan' : 'Agregar Nuevo Plan'}
                                        </Dialog.Title>
                                        <form onSubmit={handleSubmit}>
                                            <div className="mt-2">
                                                <label
                                                    htmlFor="title"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Nombre
                                                </label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    value={blog.title}
                                                    onChange={(e) => setBlog({ ...blog, title: e.target.value })}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                                {formErrors.title && <p style={{ color: 'red' }}>{formErrors.title}</p>}
                                            </div>
                                            <div className="mt-2">
                                                <label
                                                    htmlFor="price"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Precio IVA
                                                </label>
                                                <input
                                                    type="text"
                                                    name="price"
                                                    id="price"
                                                    value={blog.price}
                                                    onChange={(e) => setBlog({ ...blog, price: e.target.value })}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                                {formErrors.price && <p style={{ color: 'red' }}>{formErrors.price}</p>}
                                            </div>

                                            <div className="mt-2">
                                                <label
                                                    htmlFor="annualPrice"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Precio Anual
                                                </label>
                                                <input
                                                    type="text"
                                                    name="annualPrice"
                                                    id="annualPrice"
                                                    value={blog.annualPrice}
                                                    onChange={(e) => setBlog({ ...blog, annualPrice: e.target.value })}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                                {formErrors.annualPrice && (
                                                    <p style={{ color: 'red' }}>{formErrors.annualPrice}</p>
                                                )}
                                            </div>

                                            <div className="mt-2">
                                                <label
                                                    htmlFor="description"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Descripción
                                                </label>
                                                <ReactQuill
                                                    value={blog.description}
                                                    onChange={(content) => setBlog({ ...blog, description: content })}
                                                    className="quill-editor"
                                                    theme="snow"
                                                />
                                                {formErrors.description && (
                                                    <p style={{ color: 'red' }}>{formErrors.description}</p>
                                                )}
                                            </div>
                                            <div className="mt-4">
                                                <h4 className="text-sm font-medium text-gray-700">Características</h4>
                                                {characteristics.map((char) => (
                                                    <div key={char.id} className="flex items-center mt-2">
                                                        <input
                                                            type="checkbox"
                                                            id={`char-${char.id}`}
                                                            checked={selectedCharacteristics.includes(char.id)}
                                                            onChange={() => handleCharacteristicChange(char.id)}
                                                            className="mr-2"
                                                        />
                                                        <label
                                                            htmlFor={`char-${char.id}`}
                                                            className="text-sm text-gray-600"
                                                        >
                                                            {char.name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="mt-4 flex justify-end space-x-2">
                                                <FormButton type="submit" label="Guardar" />
                                                <FormButton type="button" label="Cancelar" onClick={closeModal} />
                                            </div>
                                        </form>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </WhiteCardContainer>
            <WhiteCardContainer>
                <FormFieldsContainer>
                    <ProfileFormHeader title="Características" />
                </FormFieldsContainer>
                <div className="px-6 mb-2">
                    <button
                        onClick={openCharacteristicModal}
                        className="bg-dl-primary-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-500 disabled:bg-dl-primary-500"
                    >
                        Agregar Nueva Característica
                    </button>
                </div>

                <div className="px-4 sm:px-6 lg:px-8 mb-10">
                    <div className="flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Nombre
                                                </th>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Acciones
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {characteristics.map((char, index) => (
                                                <tr key={char.id}>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {char.name}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="flex gap-2">
                                                            <button
                                                                onClick={() => handleEditCharacteristic(index)}
                                                                className="text-dl-primary-900 hover:text-dl-accent cursor-pointer"
                                                            >
                                                                Editar
                                                            </button>
                                                            <button
                                                                onClick={() => setDeleteCharacteristicId(char.id)}
                                                                className="text-dl-primary-900 hover:text-dl-accent cursor-pointer"
                                                            >
                                                                Borrar
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WhiteCardContainer>

            <Transition appear show={isCharacteristicModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeCharacteristicModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        {editIndex !== null ? 'Editar Característica' : 'Agregar Nueva Característica'}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            value={currentCharacteristic}
                                            onChange={(e) => setCurrentCharacteristic(e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                    <div className="mt-4 flex justify-end space-x-2">
                                        <FormButton
                                            type="button"
                                            label="Guardar"
                                            onClick={handleAddOrUpdateCharacteristic}
                                        />
                                        <FormButton type="button" label="Cancelar" onClick={closeCharacteristicModal} />
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default Plans;
