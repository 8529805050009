import { useEffect, useState } from 'react';
import createServices from '../api/createServices';
import deleteServices from '../api/deleteServices';
import { id } from 'date-fns/locale';
import { set } from 'date-fns';

export default function useDoctorServices(queryAllDoctorServices, selectedUser) {
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [modalTitle, setModalTitle]  = useState('');

    const resetState = () => {
        setShowSuccessModal(false);
        setShowErrorModal(false);
        setErrorBody('');
        setSuccessBody('');
    };

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (errorMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(errorMessage);
    };

    const saveServices = (data) => {
        if (!data.name) {
            _createErrorMessage('No se pudo obtener información válida para guardar. Inténtelo de nuevo.');
            return;
        }
        executeCreateServices(data);
    };

    const executeCreateServices = async (newService) => {
        setIsLoading(true);
        const createNewServices = await createServices(newService, _createErrorMessage);

        if (createNewServices) {
            setModalTitle('Nuevo registro guardado con éxito');
            _createSuccessMessage('¡Se ha guardado el registro con éxito!');
            setInputValue('');
        }

        setIsLoading(false);
    };

    const deleteService = async (id) => {
        console.log(id, 'id');
        if (!id) {
            _createErrorMessage('No se pudo obtener información válida para eliminar. Por favor inténtalo de nuevo.');
            return;
        }

        setIsLoading(true);

        const deletedService = await deleteServices(id, _createErrorMessage);

        if (deletedService) {
            setModalTitle('Registro eliminado exitosamente.');
            _createSuccessMessage('¡El registro se ha eliminado correctamente!');
            setInputValue('');
        }

        setIsLoading(false);
    }

    const onCloseSuccessMessage = () => {
        resetState();
        queryAllDoctorServices();
    };

    return {
        showErrorModal,
        setShowErrorModal,
        closeErrorMessage,
        showSuccessModal,
        setShowSuccessModal,
        successBody,
        errorBody,
        setErrorBody,
        onCloseSuccessMessage,
        saveServices,
        isLoading,
        resetState,
        inputValue,
        setInputValue,
        deleteService,
        modalTitle,
    };
}
